import { GET_PERIOD } from './-getter-types';
import { usePeriodsStore } from '~/stores/periods';
import { type Period } from '~/models/Period';

const Getters = {
    [GET_PERIOD]: (): Period | null => {
        const periodsStore = usePeriodsStore();

        return periodsStore.period;
    },
};

export default Getters;
