import { SET_PERIOD } from './-mutation-types';
import { usePeriodsStore } from '~/stores/periods';
import { type Period } from '~/models/Period';

const Mutations = {
    [SET_PERIOD](payload: Period | null) {
        const periodsStore = usePeriodsStore();
        periodsStore.period = payload;
    },
};

export default Mutations;
