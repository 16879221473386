import { type Period } from '~/models/Period';

export type PeriodState = {
    period: Period | null;
}

export const defaultStateData = (): PeriodState => ({
    period: null,
});

export const DefaultState = (): PeriodState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
