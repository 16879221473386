import { SET_PERIOD } from './-mutation-types';
import { usePeriodsStore } from '~/stores/periods';
import PeriodService from '~/services/api/crm/PeriodService';
import { type Period } from '~/models/Period';

const Actions = {
    async fetchPeriod({ certificateId, periodId }: { certificateId: number; periodId: number }) {
        const periodsStore = usePeriodsStore();
        const periodService = new PeriodService();
        const response = await periodService.fetchPeriod(certificateId, periodId);

        periodsStore[SET_PERIOD](response);
    },
    setPeriod(period: Period | null) {
        const periodsStore = usePeriodsStore();
        periodsStore[SET_PERIOD](period);
    },
};

export default Actions;
